
.horizontal-menu-padding{
	.header-navbar {
		.navbar-container{
			padding: 0;
		}
	}
}
.header-navbar .navbar-container{
	height: auto;
}