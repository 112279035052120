.navbar-container{

	a.nav-link-search{
		float: left;
	}
	.search-input{
		float: left;
    	padding-top: 1.2rem;
		width: 0;
		.input{
			width:0;			
			border: none;
			background: none;
			transition: all 0.2s ease-out;
			line-height: 16px;
		}
		&.open{
			.input{
				width: 136px;
				padding:5px 10px;						
				outline: none;
				background: none;				
    			transition: all 0.3s ease-out;
			}
		}
	}
}
.navbar-light, .navbar-semi-dark{
	.search-input{
		.input{
			color: #2C303B;
			&::placeholder{
				color: #2C303B;
			}
		}
		&.open{
			.input{
				color: #2C303B;
				border-bottom: 1px solid #2C303B;
				
			}
		}
	}
}
.navbar-dark, .navbar-semi-light{
	.search-input{
		.input{
			color: #F9FAFD;
			&::placeholder{
				color: #F9FAFD;
			}
		}
		&.open{
			.input{
				color: #F9FAFD;
				border-bottom: 1px solid #F9FAFD;
			}
		}
	}
}

@include media-breakpoint-down(md) {
	#navbar-mobile{
		.search-input.open{
			.input{
				width: 136px;	
				color: #2C303B;
				border-bottom: 1px solid #2C303B;
				overflow: hidden;
				position: relative;
				background: none;
				z-index: 1;
				padding: 10px 10px;
				top: -5px;
			}
		}
	}
}
@media only screen and (max-width: 576px) {
	#search-results{
		li{
			&.media{
				flex-direction: column;
			}
		}
	}
}
@media only screen and (max-width: 600px) {
	#search-results{
		.img-fluid.rounded{
			margin-right: 1rem;
		}
	}
}