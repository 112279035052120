

ul.list-inline{
	li{
		display: inline-block;
	}
	&.list-inline-pipe{
		>li + li:before{
			content: ' | ';
		    color: $gray-300;
		}
	}
}
.list-group{
	.list-group-item{
		&.active{
			color: #fff !important;
		}
	}
	.list-group-item-action{
		&:active{
			color: #fff;
	    	background-color: $info;
	    	a{
	    		color: #fff;
	    	}
		}
	}
}
.users-list{
	li+li {
    	margin-left: -14px;
	}
	li{
		img{
			border: 2px solid #fff;
    		box-shadow: 0px 2px 10px 0px rgba($gray-600, 0.3)
    	}
    	.badge{
			background: rgba($white, 0.8);
			color: $gray-600;
			margin-bottom: 6px;	
    	}
	}
}