.customizer{
	width: 270px;
    // right: -400px;
	padding: 0;
    background-color: #FFF;
	// z-index: 1051;
    position: fixed;
    // top: 0;
    bottom: 0;
    max-height: 500px;
    transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
    backface-visibility: hidden;
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    // box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
	&.open{
		right: 0;
	}
	.customizer-content{
		position: relative;
    	height: 100%;
    	.custom-control{
    		.custom-control-label{
    			top: 0;
    		}
    	}
	}
	a.customizer-toggle{
		background: #92c560;
		color:$primary;
		display: block;
		border-radius: 50%;
	}
	a.customizer-close {
    	color: #000;
	}
	.customizer-close{
		position: absolute;
	    right: 10px;
	    top: 10px;
	    padding: 7px;
	    width: auto;
	    z-index: 10;
	}
	.customizer-toggle{
		position: absolute;
		top: -11%;
		width: 100%;
		height: 47px;
		border-radius: 50%;
		left: -281px;
		line-height: 56px;
		cursor: pointer;
	}
	.color-options{
		a{
			white-space:pre;
		}
	}
	.customizer-sidebar-options .active{
		background-color: $primary;
		border-color: $primary;
		color:#FFF;
	}
}
.buy-now {
    position: fixed;
    bottom: 5%;
    right: 2%;
    z-index: 1051;
}
