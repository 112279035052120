/* You can add global styles to this file, and also import other style files */

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

/* Css for ngx-datatable */
/* @import '~@swimlane/ngx-datatable/index.css'; */
/* @import '~@swimlane/ngx-datatable/themes/material.scss'; */
@import '~@swimlane/ngx-datatable/assets/icons.css';

/* @import '~@swimlane/ngx-datatable/themes/material.css'; */
@font-face {
  font-family: data-table;
  src: url('node_modules/@swimlane/ngx-datatable/assets/fonts/data-table.ttf')
}

/* *{

} */
/* Mandatory sign */
span.mandatory-sign {
  color: #ff4961;
}

.modal-header {
  border-bottom: none !important;
}

.modal-footer {
  border-top: none !important;
}

h4#myModalLabel30 {
  font-weight: 500 !important;
}

.modal-header {
  padding-bottom: 0px !important;
}

.switch-handle-event label {
  font-size: 12px;
  font-weight: 600;
}

ngx-datatable.ngx-datatable.bootstrap.row.contacts-table .ps__rail-x {
  display: none;
}

ngx-datatable.ngx-datatable.bootstrap.row.contacts-table .ps__rail-y {
  display: none;
}

.ngx-datatable.bootstrap {
  border: none !important;
}

datatable-header.datatable-header.ng-star-inserted {
  border-top: 2px solid #ebebeb;
}

:host ::ng-deep ngx-datatable {
  width: 100% !important;
}

ngx-datatable.ngx-datatable.bootstrap.row.contacts-table {
  overflow-x: auto !important;
}

/* dashboard css start from here  */
.dash-item {
  /* // background-image: url('../../../assets/images/dashboard/item-bg.png'); */
  background-color: #ffffff;
  box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
  height: 120px;
  max-width: 18% !important;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 30px;
  margin-top: 30px !important;
}

.dash-item,
p {
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #000000;
  font-weight: 600;
  margin-top: 0px;
}

.dash-item-icon {
  text-align: center;
  width: 70px;
  height: 70px;
  border-radius: 11px;
  filter: drop-shadow(2.5px 4.33px 2.5px rgba(0, 0, 0, 0.02));
  background-color: #f94860;
  position: relative;
  top: -39px;
  margin-left: auto;
  margin-right: auto;
}

.dash-item-icon,
img {
  padding-top: 7px;
}

/* dashboard css ends from here  */

.capital_text {
  text-transform: capitalize;
}

.csr_point {
  cursor: pointer;
}

/* Agent & Team search  */
.agent-team-search {
  min-width: 55px;
}

/* table sortable css start  */
th[sortable].asc:before {
  content: '\2191';
  display: block;
  width: 22px;
  height: 22px;
  float: left;
  margin-left: -22px;
}

th[sortable].desc:before {
  content: '\2193';
  display: block;
  width: 22px;
  height: 22px;
  float: left;
  margin-left: -22px;
}

/* table sortable css ends from here  */


/* callpad css start from here  */
.w-101 {
  width: 101% !important;
}

#getSIPId {
  top: 0px;
  width: 200px;
  padding: 0px 0px 0px 10px;
}

#makeAudioCall {
  height: 25px;
  cursor: pointer;
}

#makeVideoCall {
  display: none;
  height: 25px;
  cursor: pointer
}

#reject {
  float: left;
  bottom: 0;
}

.mainContainer {
  /* width: 100%;
    height: 80%; */
}

#remoteUser {
  margin: auto;
  object-fit: cover;
  object-position: center center;
  z-index: -100;
  background-position: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: rotateY(180deg);
  right: 0;
  left: 0;
  bottom: 0;
  /* min-width: 100%;
    min-height: 100%; */
}

#localUser {
  z-index: 1;
  /* width: 350px; */
  margin: auto;
  object-fit: cover;
  object-position: center center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: rotateY(180deg);
  display: none;
}

#answer {
  float: left;
  bottom: 0;
}

.menubutton {
  text-align: center;
  height: 50px;
  width: 100px;
  border-radius: 5px;
  display: inline-block;
}

.actionOnCall ul {
  text-align: center;
}

.actionOnCall {
  width: 100%;
  height: 10%;
  position: fixed;
  display: flex;
  justify-content: flex-start;
}

.accept_call_container {
  top: 63px;
  /* bottom:178px; */
  position: relative;
  justify-content: center;
  width: 285px;
  right: -90px;
  /* top: 333px; */
  /* position: fixed; */
  /* justify-content: center; */
  /* margin-left: 31px !important; */
}

#acceptCall {
  display: none;
  /* width: 190px;
    height: 40px; */
  /* bottom: 40px; */
  /* margin-left: 30px; */
  /* top: 287px; */
  /* position: fixed; */
  /* font-size: 32px; */
  /* color: #ffffff; */
  /* width: 50px; */
  /* height: 50px; */
  /* border: none; */
  /* margin-left: -80px; */
  /* cursor: pointer; */
  /* border-radius: 50%; */
  /* background-color: #008000; */
  /* text-align: center; */
  /* padding: 9px 0px 0px 0px; */
}

#hold {
  background-color: #E80000;
  color: #FFFAFA;
  width: 80px;
  height: 20px;
  border: none;
  bottom: 0;
  left: 50%;
  margin-left: 85px;
  position: fixed;
}

#logoBuffer {
  max-width: 600px;
  margin: auto;
  display: none;
}

#loginText {
  text-align: center;
}

#formBuffer {
  max-width: 600px;
  margin: auto;
  display: none;
}

#clickLogin {
  background-color: #dd69c8;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

#clickLogin:hover {
  opacity: 0.8;
}

.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
}

.container {
  padding: 16px;
}

span.loginPwd {
  float: right;
  padding-top: 16px;
}

@media screen and (max-width: 300px) {
  span.loginPwd {
    display: block;
    float: none;
  }
}

#floatingCause {
  visibility: hidden;
  color: #000000;
  text-align: center;
  position: fixed;
  z-index: 1;
  font-size: 17px;
  flex: 0 0 100%;
  max-width: 100%;
  right: 90px;
  padding-right: 10px;
  margin-top: 43px;
}

#floatingCause {
  visibility: hidden;

}

/* #floatingCause.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  } */

#getCount {
  color: #000;
  text-align: center;
  position: fixed;
  z-index: 1;
  font-size: 17px;
  flex: 0 0 100%;
  max-width: 100%;
  right: 90px;
  margin-top: 75px;
}

#getCallerTimeCount {
  color: #000;
  text-align: center;
  position: fixed;
  z-index: 1;
  font-size: 17px;
  flex: 0 0 100%;
  max-width: 100%;
  right: 90px;
  margin-top: 75px;
}


@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

#agentCallStatus {
  visibility: hidden;
}

#agentCallStatusFull {
  visibility: hidden;
}

#floatingIncomingCause {
  display: none;
  color: #fff;
  text-align: center;
  padding: 16px;
  width: 100%;
  z-index: 1;
  font-size: 22px;
  flex: 0 0 100%;
  max-width: 100%;
  padding-top: 20px;
}

/* #floatingIncomingCause.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  } */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

#cameraOn {
  font-size: 3px;
  width: 35px;
  height: 35px;
  bottom: 40px;
  left: 50%;
  margin-left: 15px;
  position: fixed;
  display: none;
  cursor: pointer;
  border-radius: 50%;
  background-color: white;
  padding: 8px;
}

#cameraOff {
  width: 35px;
  height: 35px;
  bottom: 40px;
  left: 50%;
  margin-left: 15px;
  position: fixed;
  display: none;
  cursor: pointer;
  border-radius: 50%;
  background-color: white;
  padding: 8px;
}

#acceptCall:hover,
#terminateCall:hover,
#muteCall:hover,
#unmuteCall:hover,
#cameraOn:hover,
#cameraOff:hover {
  opacity: 0.8;
}

.pinkBg {
  background-color: #ed184f !important;
  background-image: linear-gradient(90deg, #fd5581, #fd8b55);
}

.intro-banner-vdo-play-btn {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  margin: -30px 0 0 -30px;
  border-radius: 100px;
  z-index: 1
}

.intro-banner-vdo-play-btn i {
  line-height: 56px;
  font-size: 30px
}

.intro-banner-vdo-play-btn .ripple {
  position: absolute;
  width: 160px;
  height: 160px;
  z-index: -1;
  left: 50%;
  top: 50%;
  opacity: 0;
  margin: -80px 0 0 -80px;
  border-radius: 100px;
  -webkit-animation: ripple 1.8s infinite;
  animation: ripple 1.8s infinite
}

@-webkit-keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0)
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0)
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

.intro-banner-vdo-play-btn .ripple:nth-child(2) {
  animation-delay: .3s;
  -webkit-animation-delay: .3s
}

.intro-banner-vdo-play-btn .ripple:nth-child(3) {
  animation-delay: .6s;
  -webkit-animation-delay: .6s
}

.mic_icon {
  width: 20px;
  margin-top: -5px;
  /* width: 30px; */
  /* padding-bottom: 4px !important; */
}

#callOutgoingRibble {
  display: none;
}

.zaex {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c2c;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:500,700");

.fancyFont {
  font-family: 'Montserrat';
  font-weight: 500;
}

/* call pad css ends from here  */

/* Datetime-local  */
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 1.3em;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: #e3e3e3;
}

.loader-blue div {
  background-color: #e91e63 !important;
}

.ball-spin-fade-loader {
  position: relative;
}

.loader-show.ng-star-inserted {
  background-color: #fff;
}

.file_format {
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 20px;
  color: #000000;
  font-weight: 500;
  margin-top: 0px;
  padding-top: 10px;
  margin-bottom: 0px;
}

/* ---ngb multiselect dropdown */
.multiselect-dropdown .dropdown-btn .selected-item {
  margin-bottom: 3px !important;
}

.multiselect-item-checkbox input+div:before {
  top: 61% !important;
  left: 0;
  width: 9px !important;
  height: 9px !important;
  margin-top: -9px;
}

.multiselect-item-checkbox input[type=checkbox]+div {
  color: #4e5154 !important;
}

/* //   .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
//     top: 72%;
// } */
.multiselect-item-checkbox input[type=checkbox]:checked+div:after {
  margin-left: -2px;
}

.multiselect-dropdown .dropdown-btn {
  color: #4e5154 !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  top: 4px !important;
  width: 30px !important;
  height: 38px !important;
}

.multiselect-dropdown .dropdown-list {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.dropdown-list ul {
  max-height: 240px !important;
}

.multiselect-dropdown .dropdown-btn {
  border: 1px solid #666ee8 !important;
  line-height: 1.9 !important;
}

li.multiselect-item-checkbox {
  padding: 2px 10px !important;
}

.multiselect-dropdown li.filter-textbox {
  padding: 2px 10px !important;
  border: 1px solid #ccc;
  position: relative;
  margin: 3px 4px;
  padding: 10px;
  border-radius: 2px;
}

.contact-form-heading .card-header {
  padding-bottom: 2px;
}

.sub-heading {
  margin-bottom: 0.6rem !important;
}

.info-tag {
  font-size: 1rem;
  margin-left: 6px;
  color: #1e9ff2 !important;
  /* border-radius: 50%;
  background: #1e9ff2 !important; */
  cursor: pointer;
  /* font-weight: bold; */
}

.font12 {
  font-size: 12px;
}

.font16 {
  font-size: 16px;
}

.font_weight_800 {
  font-weight: 800;
}

.font_weight_400 {
  font-weight: 400;
}

.colour_black {
  color: #000;
}